.designer{
	&__proem{
		padding-top: 40px;
		max-width: 750px;
		margin: 0 auto;
		padding-bottom: 140px;
		@media (max-width: 575px) {
			padding-top: 30px;
			padding-bottom: 90px;
		}
	}
	&__text{
		font-family: 'Montserrat';
		font-weight: 400;
		font-size: 14px;
		line-height: 28px;
		text-align: center;
		color: #000000;
		padding-bottom: 30px;
	}
	&__guide{
		font-weight: 400;
		&-title{
			font-family: 'Marcellus';
			color: #1C1C1C;
			font-size: 24px;
			text-align: center;
			padding-bottom: 80px;
			@media (max-width: 767px) {
				font-size: 18px;
				padding-bottom: 65px;
			}
		}
		&-sub{
			font-family: 'Montserrat';
			color: #000000;
			font-size: 14px;
		}
		&-scale{
			position: relative;
			max-width: 555px;
			width: 100%;
			margin: 0 auto;
			height: 1px;
			background-color: #313131;
		}
		&-low{
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			left: 0;
			text-align: left;
			min-width: 9px;
			max-width: 9px;
			height: 9px;
			border: 1px solid #000;
			border: 1px solid #000;
			background-color: #fff;
			border-radius: 50%;
			.designer__guide-property, .designer__guide-value{
				left: 0;
			}
		}
		&-mid{
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			left: 50%;
			min-width: 9px;
			max-width: 9px;
			height: 9px;
			border: 1px solid #000;
			background-color: #fff;
			border-radius: 50%;
			.designer__guide-property, .designer__guide-value{
				transform: translate(-50%, 0);
				left: 50%;
			}
		}
		&-high{
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			right: 0;
			min-width: 9px;
			max-width: 9px;
			height: 9px;
			border: 1px solid #000;
			background-color: #fff;
			border-radius: 50%;
			.designer__guide-property, .designer__guide-value{
				right: 0;
			}
		}
		&-property{
			position: absolute;
			top: -35px;
			font-family: 'Montserrat';
			font-weight: 400;
			font-size: 14px;
			line-height: 22px;
			color: #000000;
		}
		&-value{
			position: absolute;
			bottom: -45px;
			font-family: 'Marcellus';
			font-weight: 400;
			font-size: 24px;
			line-height: 30px;
			color: #1C1C1C;
			@media (max-width: 767px) {
				font-size: 16px;
				bottom: -40px;
			}
		}
	}
	&__amount{
		padding-bottom: 25px;
		@media (max-width: 767px) {
			padding-bottom: 0;
		}
		h5{
		    font-family: 'Montserrat';
		    font-size: 13px;
		    letter-spacing: 0.05em;
		    text-transform: uppercase;
		    margin-bottom: 0;
		}
	}
	.no-gutters{
		margin: 0 -7.5px;
		.dress-card{
			padding: 0 7.5px;
			&:hover{
				background-color: transparent;
			}
		}
	}
}