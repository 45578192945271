.notice{
	@media (max-width: 991px) {
		padding-bottom: 30px;
	}
}

.notifications{
	margin-top: 34px;
	background-color: #FFFFFF;
	box-shadow: 0px 4px 4px #FEEDE4;
	border-radius: 5px;
	@media (max-width: 767px) {
		margin-top: 20px;
	}
	::-webkit-scrollbar {
	    width: 4px;
	}
	::-webkit-scrollbar-track {
	    background: #ffffff; 
	}
	::-webkit-scrollbar-thumb {
	    background: #FF996D;
	    border-radius: 5px;
	}
	::-webkit-scrollbar-thumb:hover {
	    background: #FF996D; 
	    border-radius: 5px;
	}
	&__head{
		padding: 11px 40px 12px 40px;
		box-shadow: 0px 1px 1px #FEEDE4;
		@media (max-width: 767px) {
			padding: 11px 17px 12px 17px;
		}
	}
	&__body{
		padding: 0 40px;
		@media (max-width: 767px) {
			padding: 0 17px;
		}
	}
	&__item{
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding: 15px 0;
		border-bottom: 1px solid #E5E5E5;
		@media (max-width: 767px) {
			flex-wrap: wrap;
			padding: 26px 0;
		}
		&:last-child{
			border-bottom: none;
		}
	}
	&__left{
		padding-right: 15px;
		@media (max-width: 767px) {
			width: 100%;
			padding-bottom: 18px;
			padding-right: 0;
		}
	}
	&__name{
		color: #000000;
	}
	&__right{
		display: flex;
		align-items: center;
		@media (max-width: 767px) {
			width: 100%;
			flex-wrap: wrap;
		}
	}
	&__date{
		color: #8B8B8B;
		padding-right: 50px;
		@media (max-width: 767px) {
			width: 100%;
			padding-bottom: 28px;
			padding-right: 0;
		}
	}
	&__link{
		color: #000000;
		letter-spacing: 0.05em;
		font-size: 13px;
		padding: 10px 32px;
		background-color: #FFFFFF;
		border: 1px solid #FFC6AE;
		border-radius: 5px;
		text-transform: uppercase;
		transition: all .5s;
		&:hover{
			color: #FF996D;
		}
	}
}