.popup{
	background: #FFF3EC;
	box-shadow: 0px 10px 30px rgba(202, 202, 202, 0.62);
	border-radius: 5px;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: all .5s;
	z-index: -20;
	max-width: 825px;
	width: 100%;
	max-height: 100%;
    overflow: auto;
    opacity: 0;
	&.animate{
		z-index: 20000000;
		opacity: 1;
	}
	&__close{
		cursor: pointer;
		position: absolute;
		top: 20px;
		right: 20px;
		min-width: 15px;
		max-width: 15px;
		height: 15px;
		background-image: url(../images/admin/close.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		transition: all .5s;
		&:hover{
			opacity: 0.5;
		}
	}
	&__offer{
		max-width: 660px;
		padding: 32px 48px 56px 48px;
		@media (max-width: 991px) {
			padding: 30px 15px;
		}
	}
	&__sold{
		padding: 60px 53px;
		@media (max-width: 991px) {
			padding: 30px 15px;
		}
	}
	&__bought{
		padding: 60px 53px;
		@media (max-width: 991px) {
			padding: 30px 15px;
		}
	}
	&__title{
		font-family: 'Marcellus';
		font-weight: 400;
		font-size: 24px;
		color: #313131;
		padding-bottom: 30px;
	    padding-right: 30px;
	}
	&__twain{
		display: flex;
		flex-wrap: wrap;
		margin-left: -70px;
	}
	&__shell{
		padding: 10px 0 10px 70px;
		.price-card{
			padding: 0;
			padding-top: 18px;
			box-shadow: none;
		}
	}
	&__sense{
		font-family: 'Montserrat';
		font-weight: 400;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #000000;
	}
	&__price{
		padding-top: 18px;
		font-family: 'Marcellus';
		font-weight: 400;
		font-size: 24px;
		color: #313131;
	}
	&__btn{
		padding-top: 30px;
	}
	&__match{
		display: flex;
		@media (max-width: 991px) {
			flex-wrap: wrap;
		}
	}
	&__photo{
		width: 38%;
		padding-right: 48px;
		@media (max-width: 991px) {
			width: 100%;
		}
	}
	&__img{
		max-width: 100%;
	}
	&__parameters{
		width: 62%;
		@media (max-width: 991px) {
			width: 100%;
			padding-top: 30px;
		}
	}
	&__string{
		display: flex;
		padding-bottom: 16px;
	}
	&__property{
		width: 50%;
		padding-right: 15px;
		font-family: 'Montserrat';
		font-weight: 400;
		font-size: 14px;
		text-transform: capitalize;
		color: #8B8B8B;
	}
}