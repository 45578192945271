.confirmation{
	padding-top: 66px;
	padding-bottom: 130px;
	&__content{
		max-width: 380px;
		padding-bottom: 10px;
	}
	&__btn{
		padding-bottom: 20px;
		.btn{
			max-width: 205px;
			width: 100%;
		}
	}
}