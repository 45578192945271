.information{
	padding-top: 60px;
	padding-bottom: 136px;
	&__pivot{
		background: #FFF3EC;
		box-shadow: 0px 4px 4px rgba(202, 202, 202, 0.33);
		border-radius: 5px;
		padding: 40px 65px 70px 65px;
		text-align: center;
		font-weight: 400;
		height: 100%;
		.btn{
			max-width: 206px;
			width: 100%;
		}
		@media (max-width: 991px) {
			margin-top: 20px;
			height: auto;
			padding: 30px 15px;
		}
	}
	&__caption{
		font-size: 24px;
		line-height: 30px;
		text-transform: capitalize;
		color: #1C1C1C;
		font-family: 'Marcellus';
		padding-bottom: 13px;
	}
	&__hain{
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 37px;
	}
	&__arrow{
		margin: 0 10px;
	}
	&__price{
		font-family: 'Marcellus';
		font-size: 24px;
		line-height: 30px;
		text-transform: capitalize;
		color: #1C1C1C;	
	}
	&__text{
		padding-bottom: 37px;
		font-family: 'Montserrat';
		font-size: 14px;
		line-height: 22px;
		color: #000000;
	}
	&__row{
		padding-bottom: 30px;
		.nice-select.open .list{
			width: 100%;
		}
	}
	&__question{
		font-family: 'Montserrat';
		font-size: 12px;
		color: #000000;
	}
	&__pick{
		padding-top: 22px;
		.checkmark{
			background-color: #fff;
		}
	}
	&__label{
		font-family: 'Montserrat';
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		color: #000000;
		width: 100%;
		margin-bottom: 10px;
	}
	&__field{
		background: #FFFFFF;
		border: 1px solid #FFC6AE;
		border-radius: 5px;
		padding: 6px 10px;
		width: 100%;
	}
	&__textarea{
		background: #FFFFFF;
		border: 1px solid #FFC6AE;
		border-radius: 5px;
		padding: 6px 10px;
		width: 100%;
		min-height: 110px;
		max-height: 110px;
		resize: none;
	}
	&__latent{
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		&.hide{
			display: none;
		}
	}
}