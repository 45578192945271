.profile{
	margin-top: 33px;
	background-color: #FFFFFF;
	box-shadow: 0px 4px 4px #FEEDE4;
	border-radius: 5px;
	padding: 20px 38px 70px 38px;
	@media (max-width: 991px) {
		padding: 15px 15px 40px 15px;
	}
	&__basis{
		max-width: 264px;
		width: 100%;
		margin: 0 auto;
		@media (max-width: 991px) {
			padding-bottom: 37px;
		}
	}
	&__portrait{
		max-width: 210px;
		min-width: 210px;
		width: 100%;
		height: 210px;
		position: relative;
		margin: 0 auto;
		&.verified{
			&:after{
				content: '';
				display: block;
				position: absolute;
				left: 50%;
				top: 55px;
				width: 62%;
				height: 62%;
				transform: translate(-50%, 0);
				background: rgba(255, 198, 174, 0.5);
				filter: blur(15px);
				z-index: 1;
				border-radius: 50%;
			}
			&:before{
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-image: url(../images/admin/userCircle.png);
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				z-index: 2;
			}
		}
		&.unverified{
			&:after{
				content: '';
				display: block;
				position: absolute;
				left: 50%;
				top: 55px;
				width: 62%;
				height: 62%;
				transform: translate(-50%, 0);
				background: #8B8B8B;
				opacity: 0.2;
				filter: blur(15px);
				z-index: 1;
				border-radius: 50%;
			}
			&:before{
				content: '';
				display: block;
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-image: url(../images/admin/userCircle-gray.png);
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
				z-index: 2;
			}
		}
	}
	&__img{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		overflow: hidden;
		border-radius: 50%;
		padding: 35px;
	}
	&__circle{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
	&__status{
		text-align: center;
		color: #8B8B8B;
		font-size: 14px;
		line-height: 17px;
		padding-top: 30px;
	}
	&__verified{
		display: inline-flex;
		align-items: center;
		color: #FF996D;
		font-size: 14px;
		line-height: 17px;
		padding-left: 18px;
		&:after{
			content: '';
			display: block;
			max-width: 19px;
			min-width: 19px;
			height: 19px;
			margin-left: 11px;
			background-image: url(../images/admin/check.svg);
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}
	}
	&__unverified{
		color: #8B8B8B;
		font-size: 14px;
		line-height: 17px;
		padding-left: 18px;
	}
	&__box{
		display: flex;
		flex-wrap: wrap;
		margin-left: -28px;
		@media (max-width: 767px) {
			margin-left: 0;
		}
	}
	&__row{
		width: 50%;
		padding-left: 28px;
		padding-bottom: 30px;
		@media (max-width: 991px) {
			padding-bottom: 20px;
		}
		@media (max-width: 767px) {
			width: 100%;
			padding-left: 0;
		}
	}
	&__label{
		width: 100%;
		margin-bottom: 0;
		padding-bottom: 11px;
		font-size: 14px;
		line-height: 22px;
		color: #000;
	}
	&__field{
		width: 100%;
		background-color: #FFFFFF;
		border: 1px solid #FFC6AE;
		border-radius: 5px;
		padding: 5px;
	}
	&__question{
		font-size: 12px;
		line-height: 16px;
		color: #FF996D;
	}
	&__pick{
		margin-top: 14px;

		.checkmark{
			background-color: #fff;
		}
		.radio-container{
			margin-bottom: 0;
			margin-right: 45px;
		}
	}
	&__basement{
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		flex-wrap: wrap;
		@media (max-width: 991px) {
			justify-content: center;
			margin-top: 30px;
		}
	}
	&__btn{
		@media (max-width: 991px) {
			display: block;
			width: 100%;
			text-align: center;
			padding-bottom: 20px;
		}
	}
	&__close{
		color: #FF996D;
		font-size: 14px;
		line-height: 22px;
		cursor: pointer;
	}
}