.messages{
	margin-top: 30px;
	padding: 22px 20px;
	background: #FFFFFF;
	box-shadow: 0px 4px 4px #FEEDE4;
	border-radius: 5px;
	position: relative;
	&:after{
		content: '';
		position: absolute;
		left: 0;
		top: 80px;
		width: 100%;
		height: 1px;
		background-color: #E5E5E5;
		@media (max-width: 991px) {
			display: none;
		}
	}
	&__chats{
		padding: 22px 30px;
		background: #FFF3EC;
		box-shadow: 0px 4px 4px rgba(202, 202, 202, 0.33);
		border-radius: 5px;
	    height: 100%;
		@media (max-width: 991px) {
			padding: 0;
			.collapse{
				margin-top: 0;
			}
		}
	}
	&__utilizer{
		padding: 22px 20px 22px 0;
		@media (max-width: 991px) {
			padding: 30px 0 0 0;
		}
	}
	&__title{
		text-align: center;
		font-family: 'Montserrat';
		font-weight: 400;
		font-size: 13px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #000000;
		padding-bottom: 49px;
		@media (max-width: 991px) {
			padding: 12px 0;
			margin-bottom: 25px;
			border-bottom: 1px solid #E5E5E5;
		}
	}
	&__toggle{
		cursor: pointer;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #FFFFFF;
		border: 1px solid #FFC6AE;
		border-radius: 5px;
		padding: 5px 18px;
		font-family: 'Montserrat';
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		color: #000000;
		&.collapsed{
			.messages__arrow{
				transform: rotate(180deg);
			}
		}
		@media (max-width: 991px) {
			margin: 0 15px 30px 15px;
		}
	}
	&__arrow{
		margin-right: 10px;
	}
	&__twain{
		padding-right: 15px;
	}
	&__all{
		padding-top: 27px;
		@media (max-width: 991px) {
			padding-top: 0;
		}
	}
	&__station{
		display: flex;
		align-items: center;
		padding: 22px 18px;
		margin-bottom: 10px;
		cursor: pointer;
		overflow: hidden;
		transition: all .5s;
		&.active{
			cursor: default;
			background: #FBE6D9;
			border-radius: 5px;
		}
		&:last-child{
			margin-bottom: 0;
			@media (max-width: 991px) {
				border-bottom: none;
			}
		}
		@media (max-width: 991px) {
			border-bottom: 1px solid #E5E5E5;
			margin-bottom: 0;
		}
	}
	&__photo{
		width: 20%;
	}
	&__img{
		min-width: 40px;
		max-width: 40px;
		height: 40px;
		border-radius: 50%;
	    object-fit: cover;
	}
	&__facts{
		padding-left: 16px;
		font-family: 'Montserrat';
		font-weight: 400;
		width: 80%;
	}
	&__name{
		font-size: 14px;
		color: #000000;
		padding-bottom: 2px;
	}
	&__word{
		font-size: 13px;
		color: #8B8B8B;
		white-space: nowrap;
	    overflow: hidden;
	    text-overflow: ellipsis;
	}
	&__suite{
		height: 0;
		overflow: hidden;
		transition: all .5s;
		&.active{
			height: auto;
		}
	}
	&__personality{
		text-align: right;
		display: flex;
		justify-content: flex-end;
		padding-bottom: 38px;
		.messages__content{
			padding-right: 10px;
		}
		.messages__report{
			background: #FBE6D9;
		}
		@media (max-width: 991px) {
			padding-bottom: 30px;
		}

	}
	&__another{
		display: flex;
		text-align: left;
		justify-content: flex-start;
		padding-bottom: 38px;
		.messages__content{
			padding-left: 10px;
		}
		.messages__report{
			background: #FFF3EC;
		}
		@media (max-width: 991px) {
			padding-bottom: 30px;
		}
	}
	&__doublet{
		display: flex;
		width: auto;
	}
	&__report{
		text-align: left;
		border-radius: 5px;
		padding: 16px 20px;
		font-size: 13px;
		font-family: 'Montserrat';
		color: #000;
	}
	&__who{
		font-family: 'Montserrat';
		font-weight: 400;
		font-size: 14px;
		color: #8B8B8B;
		padding-bottom: 3px;
	}
	&__shape{
		margin-top: 24px;
	}
	&__form{
		padding-top: 120px;
		text-align: right;
		.btn{
			padding: 13px 46px;
		}
		@media (max-width: 991px) {
			padding-top: 0;
		}
	}
	&__textarea{
		border: 1px solid #FFC6AE;
		border-radius: 5px;
		resize: none;
		padding: 12px 22px;
		font-family: 'Montserrat';
		font-weight: 400;
		font-size: 13px;
		color: #8B8B8B;
		width: 100%;
		min-height: 150px;
		max-height: 150px;
		margin-bottom: 20px;
		&:focus{
			outline: none;
		}
		&::placeholder{ 
			color: #8B8B8B;
		}
	}
}