.wishlist{
	background-color: #FFFFFF;
	box-shadow: 0px 4px 4px #FEEDE4;
	border-radius: 5px;
	padding: 30px 40px;
	margin-top: 26px;
	@media (max-width: 991px) {
		padding: 17px 17px 60px 17px;
	}
	&__plate{
		margin-left: -30px;
		display: flex;
		flex-wrap: wrap;
		@media (max-width: 991px) {
			margin-left: -20px;
		}
	}
	&__box{
		width: 25%;
		padding-left: 30px;
		padding-bottom: 30px;
		@media (max-width: 991px) {
			width: 33.3%;
			padding-left: 20px;
		}
		@media (max-width: 767px) {
			width: 50%;
		}
	}
	&__product{
		position: relative;
		padding-bottom: 140%;
	}
	&__heart{
		position: absolute;
		right: 9px;
		top: 9px;
		min-width: 20px;
		max-width: 20px;
		height: 20px;
		cursor: pointer;
	}
	&__img{
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 3px;
	}
	&__name{
		padding-top: 20px;
		padding-bottom: 12px;
		font-size: 13px;
		line-height: 16px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #000000;
	}
	&__size{
		font-size: 14px;
		line-height: 22px;
		color: #515151;
		padding-bottom: 10px;
	}
	&__price{
		font-size: 13px;
		line-height: 16px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #000000;
	}
	&__cellar{
		margin-top: 20px;
		text-align: center;
	}
}