.dashboard{
	&__notifications{
		&_body{
			padding-left: 40px;
			padding-right: 35px;
			margin-right: 5px;
			max-height: 284px;
			height: 100%;
			overflow: auto;
			@media (max-width: 767px) {
				padding-left: 17px;
				padding-right: 15px;
				margin-right: 2px;
			}
		}
	}
	&__box{
		background-color: #FFFFFF;
		box-shadow: 0px 4px 4px #FEE8DD;
		border-radius: 5px;
		margin-top: 34px;
		padding: 20px 38px;
		@media (max-width: 767px) {
			padding: 20px 17px;
			margin-top: 20px;
		}
	}
	&__link{
		margin-top: 8px;
		background-color: #FFC6AE;
		border-radius: 5px;
		padding: 11px 34px;
		color: #000000;
		font-size: 13px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		transition: all .5s;
		&:hover{
			color: #000000;
			background-color: #FF996D;
		}
	}
	&__detail{
		padding-top: 20px;
		text-align: center;
	    // max-height: 240px;
		// height: 100%;
	}
	&__diagram{
		width: 100% !important;
	}
	&__purchases{
		margin-top: 20px;
		padding: 70px;
		background-image: url(../images/admin/diagram4.png);
		background-repeat: no-repeat;
		background-position: bottom center;
		background-size: contain;
		text-align: center;
		&_price{
			font-family: 'Marcellus';
			font-style: normal;
			font-weight: 400;
			font-size: 24px;
			color: #313131;
		}
		&_quantity{
			color: #8B8B8B;
			font-family: Montserrat;
			font-weight: 400;
			font-size: 14px;
		}
	}
}