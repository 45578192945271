.beadroll{
	padding: 20px 38px 0 40px;
	background-color: #FFFFFF;
	box-shadow: 0px 4px 4px #FEEDE4;
	border-radius: 5px;
	margin-top: 26px;
	@media (max-width: 991px) {
		padding: 0 0 40px 0;
	}
}

.sked{
	padding: 20px 38px 0 40px;
	background-color: #FFFFFF;
	box-shadow: 0px 4px 4px #FEEDE4;
	border-radius: 5px;
	margin-top: 26px;
	@media (max-width: 991px) {
		background-color: transparent;
		padding: 0 0 40px 0;
		box-shadow: none;
	}
	.table{
		@media (max-width: 991px) {
			display: none;
		}
		&__td{
			&:first-child{
				padding-left: 0 !important;
			}
			&:last-child{
				padding-right: 0 !important;
			}
		}
	}
	&__list{
		display: none;
		@media (max-width: 991px) {
			display: block;
		}
	}
	&__box{
		padding: 26px 16px 18px 16px;
		background-color: #FFFFFF;
		box-shadow: 0px 4px 4px #FEEDE4;
		border-radius: 5px;
		margin-bottom: 26px;
	}
	&__row{
		display: flex;
		align-items: center;
		margin-bottom: 8px;
	}
	&__property{
		font-size: 14px;
		color: #8B8B8B;
		padding-right: 10px;
		white-space: nowrap;
	}
	&__value{
		font-size: 12px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #000000;
	}
	&__couple{
		padding-top: 12px;
		display: flex;
		margin-left: -30px;
		padding-bottom: 25px;
	}
	&__shell{
		padding-left: 30px;
	}
	&__unit{
		display: flex;
		align-items: flex-start;
		margin-bottom: 1px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	&__picture{
		border-radius: 5px;
		max-width: 86px;
		min-width: 86px;
		height: 86px;
		overflow: hidden;
		position: relative;
	}
	&__img{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
	&__cellar{
		text-align: center;
		margin-bottom: 8px;
	}
	&__pagination{
		@media (max-width: 991px) {
			background-color: #FFFFFF;
			box-shadow: 0px 4px 4px #FEEDE4;
			border-radius: 5px;
		}
	}
	&__btn{
		padding-top: 6px;
		max-width: 100px;
	}
}