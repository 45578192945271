.support{
	padding-top: 66px;
	padding-bottom: 130px;
	&__content{
		max-width: 334px;
		@media (max-width: 991px) {
			padding-bottom: 30px;
			max-width: 100%;
		}
	}
	&__title{
		font-family: 'Marcellus';
		font-weight: 400;
		font-size: 24px;
		color: #1C1C1C;
		padding-bottom: 24px;
	}
	&__text{
		padding-top: 8px;
		font-family: 'Montserrat';
		font-weight: 400;
		font-size: 14px;
		color: #000000;
	}
	&__pivot{
		background: #FFF3EC;
		box-shadow: 0px 4px 4px rgba(202, 202, 202, 0.33);
		border-radius: 5px;
		padding: 40px;
		@media (max-width: 991px) {
			padding: 15px;
		}
	}
	&__row{
		padding-bottom: 24px;
		.nice-select.open .list{
			width: 100%;
		}
	}
	&__cellar{
		.btn{
			margin: 6px 0;
			&:first-child{
				margin-right: 30px;
			}
		}
	}
	&__adding{
		margin-top: 14px;
		font-family: 'Montserrat';
		font-weight: 400;
		font-size: 14px;
		color: #FF996D;
		transition: all .5s;
		cursor: pointer;
		&:hover{
			color: #FF996D;
			opacity: 0.5;
		}
	}
	&__file{
		padding-bottom: 24px;
		display: none;
		.upload-btn-wrapper{
			margin-top: 0;
		}
		&.show{
			display: block;
		}
	}
}