.dress{
	margin-top: 20px;
	.appearance-area{
		margin-top: 64px;
	}
}

.d-listing-area{
	padding-top: 65px;
	padding-bottom: 15px;
}

.listing{
	&-card{
		padding: 38px 30px;
		background: #FFFAFA;
		box-shadow: 0px 4px 4px rgba(202, 202, 202, 0.33);
		border-radius: 5px;
		transition: all .5s;
		cursor: pointer;
		&.active{
			background: #FFF3EC;
			cursor: default;
		}
		&:hover{
			background: #FFF3EC;
		}
	}
	&-icon{
		min-width: 56px;
		max-width: 56px;
		height: 56px;
	}
	&-name{
		padding-top: 14px;
		padding-bottom: 11px;
		font-family: 'Montserrat';
		font-weight: 400;
		font-size: 13px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #000000;
	}
	&-text{
		font-family: 'Montserrat';
		font-weight: 400;
		font-size: 14px;
		color: #5B5B5B;
	}
}

.d-shipping-area{
	padding-top: 65px;
	padding-bottom: 40px;
}

.d-shipping-area .price-card{
	padding: 42px 30px;
}

.d-shipping-area .price-card label{
	padding-top: 0;
	margin-right: 0;
}