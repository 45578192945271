.pagination{
	padding: 60px 10px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: 991px) {
		padding: 20px 10px;
	}
	@media (max-width: 375px) {
		justify-content: space-between;
	}
	&__prev{
		color: #000000;
		letter-spacing: 0.05em;
		font-size: 13px;
		padding: 8px 17px 8px 17px;
		border: 1px solid #FFC6AE;
		border-radius: 5px;
		transition: all .5s;
		&:hover{
			color: #FF996D;
		}
	}
	&__next{
		color: #000000;
		letter-spacing: 0.05em;
		font-size: 13px;
		padding: 8px 17px 8px 17px;
		background-color: #FFC6AE;
		border-radius: 5px;
		transition: all .5s;
		&:hover{
			color: #000000;
			background-color: #FF996D;
		}
	}
	&__pages{
		margin: 0 3.24%;
	}
	&__item{
		font-size: 14px;
		color: #8B8B8B;
		margin: 0 7px;
		&:hover{
			color: #8B8B8B;
		}
		&.active{
			color: #000000;
			cursor: default;
		}
	}
}