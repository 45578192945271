@font-face {
    font-family: 'Good Vibes Pro';
    src: url('../fonts/GoodVibesPro.eot');
    src: url('../fonts/GoodVibesPro.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GoodVibesPro.woff2') format('woff2'),
        url('../fonts/GoodVibesPro.woff') format('woff'),
        url('../fonts/GoodVibesPro.ttf') format('truetype'),
        url('../fonts/GoodVibesPro.svg#GoodVibesPro') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}