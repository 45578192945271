.table{
	margin-bottom: 0;
	&__thead{
		background-color: #FFF3EC;
		border-radius: 50px;
		width: 100%;
		overflow: hidden;
		overflow: visible;
		
		@media (max-width: 991px) {
			display: none;
		}
	}
	&__th{
		border-top: none !important;
		border-bottom: none !important;	
		padding: 17px 12px !important;
		color: #8B8B8B;
		font-size: 14px;
		font-weight: 400;
	}
	&__tbody{
		.table{
			&__tr{
				border-bottom: 1px solid #E5E5E5;
				&:last-child{
					border-bottom: none;
				}
			}
		}
	}
	&__tr{
		@media (max-width: 991px) {
			display: block;
			padding: 24px 17px;
		}
	}
	&__td{
		border-top: none !important;
		vertical-align: middle !important;
		padding: 28px 12px !important;
		font-size: 12px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #000000;
		@media (max-width: 991px) {
			padding: 5px 0 !important;
			display: flex;
			align-items: center;
			&:before{
				content: attr(aria-label);
				margin-right: 12px;
				color: #8B8B8B;
				font-size: 14px;
				text-transform: none;
				white-space: nowrap;
			}
		}
	}


	&__picture{
		border-radius: 5px;
		max-width: 54px;
		min-width: 54px;
		height: 54px;
		overflow: hidden;
		position: relative;
	}
	&__img{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
	&__icons{
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	&__knob{
		width: 31px;
		height: 31px;
		border: 1px solid #FFC6AE;
		border-radius: 3px;
		overflow: hidden;
		background-repeat: no-repeat;
		background-position: center;
		background-size: auto;
		transition: all .5s;
		cursor: pointer;
		margin-left: 10px;
		&:first-child{
			margin-left: 0;
		}
		&:hover{
			background-color: #FFC6AE;
		}
	}
	&__btn{
		max-width: 87px;
		width: 100%;
		padding: 5px;
		font-size: 12px;
		text-align: center;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #000000;
		border: 1px solid #FFC6AE;
		border-radius: 5px;
		background-color: transparent;
		transition: all .5s;
		&:hover{
		    color: #FF996D;
		}
		&:focus{
			outline: none;
		}
		@media (max-width: 991px) {
			max-width: 147px;
			padding: 10px;
		}
	}
	&__delete{
		border: none;
		cursor: pointer;
		background: #FFA798;
		border-radius: 3px;
		width: 31px;
		height: 31px;
		background-image: url(../images/admin/del.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: auto;
		transition: all .5s;
		margin-left: 10px;
		&:hover{
		    opacity: 0.5;
		}
	}
	&__accept{
		border: none;
		cursor: pointer;
		background: #89EEAE;
		border-radius: 3px;
		width: 31px;
		height: 31px;
		background-image: url(../images/admin/accept.svg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: auto;
		transition: all .5s;
		margin-left: 10px;
		&:hover{
		    opacity: 0.5;
		}
	}
}