.sidebar{
	padding: 22px 0;
	background-color: #FFFFFF;
	box-shadow: 1px 1px 1px rgba(255, 198, 174, 0.25);
    height: 100%;
    @media (max-width: 767px) {
		display: none;
	}
	&__item{
		padding: 11px 21px;
		display: flex;
		align-items: center;
		transition: all .5s;
		&:hover{
			background-color: rgba(255,153,109, 0.2);
		}
		&.active{
			position: relative;
			cursor: default;
			&:after{
				position: absolute;
				right: 0;
				top: 50%;
				transform: translate(0, -50%);
				content: '';
				width: 2px;
				height: 22px;
				background-color: #FF996D;
			}
			.sidebar{
				&__img{
					opacity: 0;
					&_hover{
						opacity: 1;
					}
				}
				&__name{
					color: #000000;
				}
			}
			&:hover{
				background-color: transparent;
			}
		}
	}
	&__icon{
		max-width: 25px;
		min-width: 25px;
		height: 25px;
		overflow: hidden;
		margin-right: 22px;
		position: relative;
	}
	&__img{
		max-width: 100%;
		&_hover{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
		}
	}
	&__name{
		color: #8B8B8B;
		font-size: 14px;
		line-height: 17px;
	}
}