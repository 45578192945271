.order{
	padding-top: 50px;
	padding-bottom: 130px;
	&__head{
		padding-bottom: 42px;
		display: flex;
		flex-wrap: wrap;
	}
	&__caption{
		font-size: 24px;
		color: #1C1C1C;
		font-family: 'Marcellus';
		padding-right: 30px;
	}
	&__title{
		font-size: 24px;
		color: #1C1C1C;
		font-family: 'Marcellus';
		padding-bottom: 32px;
	}
	&__important{
		font-family: 'Montserrat';
		font-size: 12px;
		color: #FF996D;
	}
	&__still{
		display: none;
		&.show{
			display: flex;
		}
	}
	&__pivot{
		background: #FFF3EC;
		box-shadow: 0px 4px 4px rgba(202, 202, 202, 0.33);
		border-radius: 5px;
		padding: 30px 20px 46px 20px;
		font-family: 'Montserrat';
		font-weight: 400;
		font-size: 14px;
		color: #000000;
	}
	&__couple{
		display: flex;
	}
	&__product{
		min-width: 107px;
		max-width: 107px;
		height: 167px;
		position: relative;
		overflow: hidden;
	}
	&__img{
		position: absolute;
		left: 50%;
		top: 50%;
		height: 100%;
		transform: translate(-50%, -50%);
	}
	&__content{
		padding-left: 20px;
	}
	&__values{
		padding-top: 38px;
	}
	&__seller{
		padding-top: 30px;
		padding-bottom: 22px;
	}
	&__prices{
		padding-bottom: 31px;
	}
	&__string{
		border: 1px solid #FFC6AE;
		border-bottom: none;
		padding: 7px 20px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		&:last-child{
			border-bottom: 1px solid #FFC6AE;
		}
	}
	&__cost{
		font-family: 'Marcellus';
		font-size: 24px;
		color: #1C1C1C;
		padding-left: 15px;
	}
	&__basement{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	&__series{
		max-width: 100%;
		margin-left: 15px;
	}
}