.designers{
	&__banner{
		background-image: url(../images/designers/assortment.jpg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		padding: 8% 0;
	}
	&__hat{
		max-width: 945px;
		margin: 0 auto;
		position: relative;
		display: flex;
		align-items: center;
		background: rgba(255, 255, 255, 0.7);
		border-radius: 5px;
		padding: 24px 6% 24px 6%;
	}
	&__search{
		border: none;
		width: 100%;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		background-color: transparent;
		&::placeholder{
			color: #000000;
		}
		&:focus::-webkit-input-placeholder{ 
			opacity: 0; 
			transition: opacity 0.3s ease;
		}
		&:focus:-moz-placeholder{ 
			opacity: 0; 
			transition: opacity 0.3s ease; 
		} 
		&:focus::-moz-placeholder{ 
			opacity: 0; 
			transition: opacity 0.3s ease; 
		}
		&:focus:-ms-input-placeholder{ 
			opacity: 0; 
			transition: opacity 0.3s ease; 
		}
	}
	&__btn{
		display: block;
		min-width: 19px;
		max-width: 19px;
		height: 19px;
		background-image: url(../images/icons/search.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
		background-color: transparent;
		border: none;
		margin-right: 20px;
	}
	&__basis{
		padding-top: 60px;
		padding-bottom: 120px;
		h1{
			font-size: 46px;
			margin-bottom: 34px;
			text-align: center;
		}
	}
	&__investment{
		display: flex;
		flex-wrap: wrap;
		@media (max-width: 991px) {
			flex-wrap: nowrap;
			align-items: flex-start;
		}
	}
	&__adoption{
		width: 100%;
		border: 1px solid #E5E5E5;
		border-radius: 5px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		padding: 12px;
		@media (max-width: 991px) {
			width: 70px;
		}
	}
	&__value{
		margin: 10px 11px;
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		color: #000000;
		cursor: pointer;
		@media (max-width: 991px) {
			width: 100%;
			text-align: center;
		}
		&.active{
			cursor: default;
			color: #FF996D;
		}
		&_pc{
			@media (max-width: 991px) {
				display: none;
			}
		}
		&_mobile{
			display: none;
			@media (max-width: 991px) {
				display: block;
			}
		}
	}
	&__outcome{
		padding-top: 34px;
		display: flex;
		width: 100%;
		@media (max-width: 991px) {
			width: 90%;
			padding-left: 30px;
			padding-top: 0;
		}
	}
	&__given{
		font-family: 'Marcellus';
		padding-right: 65px;
		font-weight: 400;
		font-size: 46px;
		line-height: 58px;
		text-align: center;
		text-transform: capitalize;
		color: #FF996D;
		text-align: left;
		@media (max-width: 991px) {
			padding-right: 20px;
			font-size: 18px;
		}
	}
	&__material{
		margin-left: -15px;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		@media (max-width: 767px) {
			width: auto;
		}
	}
	&__name{
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		color: #000000;
		width: 25%;
		padding: 5px 0 5px 15px;
		@media (max-width: 991px) {
			width: 33.3%;
		}
		@media (max-width: 767px) {
			width: 100%;
		}
	}
	&__number{
		color: #FF996D;
		padding-left: 5px;
	}
}
