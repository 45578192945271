.entrance{
	text-align: center;
	padding-top: 60px;
	padding-bottom: 80px;
	background-image: url(../images/sell-dress/entrance.jpg);
	background-repeat: no-repeat;
	background-position: center;
    background-size: cover;
    @media (max-width: 767px) {
		padding-top: 42px;
		padding-bottom: 55px;
	}
    &-title{
    	padding-bottom: 47px;
    	h1{
    		font-size: 46px;
    		text-align: center;
    		color: #FFFFFF;
    		margin-bottom: 0;
    		@media (max-width: 767px) {
    			font-size: 24px;
    		}
    	}
    	@media (max-width: 767px) {
			padding-bottom: 17px;
		}
    }
    &-topic{
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		color: #FFFFFF;
		padding-bottom: 12px;
		&__sub{
			font-family: 'Good Vibes Pro';
			font-weight: 400;
			font-size: 40px;
			line-height: 50px;
			text-align: center;
			text-transform: capitalize;
			color: #FFFFFF;
			padding-bottom: 47px;
			@media (max-width: 767px) {
				font-size: 36px;
				padding-bottom: 30px;
			}
		}
    }
    &-set{
    	max-width: 800px;
    	margin: 0 auto;
    	padding-bottom: 40px;
    	@media (max-width: 767px) {
			padding-bottom: 10px;
		}
    	&__box{
    		padding-bottom: 28px;
    	}
    	&__circle{
    		display: flex;
    		align-items: center;
    		justify-content: center;
    		border: 1px solid #FFFFFF;
    		min-width: 94px;
    		max-width: 94px;
    		height: 94px;
    		margin: 0 auto;
    		border-radius: 50%;
    		padding: 10px;
    		@media (max-width: 767px) {
				min-width: 58px;
	    		max-width: 58px;
	    		height: 58px;
	    		padding: 16px;
			}
    	}
    	&__icon{
    		max-width: 100%;
    	}
    	&__name{
    		padding-top: 22px;
    		font-size: 13px;
			line-height: 20px;
			text-align: center;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: #FFFFFF;
			@media (max-width: 767px) {
				font-size: 12px;
	    		padding-top: 14px;
	    	}
    	}
    }
}

.why{
	padding-top: 256px;
	padding-bottom: 100px;
	@media (max-width: 767px) {
		padding-top: 50px;
		padding-bottom: 35px;
	}
	&-title{
		position: relative;
		padding-right: 26%;
		h2{
			margin-bottom: 0;
			padding-bottom: 20px;
			font-size: 46px;
			@media (max-width: 767px) {
				font-size: 24px;
	    	}
		}
		@media (max-width: 991px) {
			padding-right: 0;
    	}
	}
	&-img{
	    position: absolute;
	    left: 85%;
	    top: -78%;
	    max-width: 30vw;
	    @media (max-width: 1440px) {
		    left: auto;
			right: 0;
			max-width: 24%;
    	}
    	@media (max-width: 991px) {
			position: relative;
			top: 0;
			display: block;
			margin-left: auto;
			max-width: 73%;
    	}
	}
	&-mass{
		padding-top: 49px;
		display: flex;
		align-items: flex-end;
		@media (max-width: 991px) {
			flex-wrap: wrap;
			padding-top: 20px;
    	}
	}
	&-picture{
		width: 47%;
		@media (max-width: 991px) {
			width: calc(100% + 30px);
			order: 2;
			margin: 0 -15px;

    	}
	}
	&-content{
		width: 53%;
	    padding-left: 5%;
    	padding-bottom: 6%;
    	@media (max-width: 991px) {
    		width: 100%;
			padding-left: 0;
			order: 1;
			padding-bottom: 30px;
    	}
	}
	&-name{
		max-width: 415px;
		font-size: 24px;
		margin-bottom: 0;
		@media (max-width: 767px) {
			font-size: 18px;
    	}
	}
	&-text{
		padding-top: 24px;
		font-size: 14px;
		line-height: 22px;
		color: #000000;
	}
}

.lowest{
	padding-top: 100px;
	padding-bottom: 50px;
	text-align: center;
	@media (max-width: 767px) {
		padding-top: 35px;
		padding-bottom: 30px;
	}
	&-title{
		padding-bottom: 32px;
		h2{
			margin-bottom: 0;
			font-size: 24px;
			@media (max-width: 767px) {
				font-size: 18px;
			}
		}
		@media (max-width: 767px) {
			padding-bottom: 15px;
		}
	}
	&-text{
		max-width: 630px;
		margin: 0 auto;
		font-size: 14px;
		line-height: 22px;
		color: #000000;
	}
}

.only{
	background: #FFF3EC;
	text-align: center;
	padding: 63px 0;
	@media (max-width: 767px) {
		padding-top: 40px;
		padding-bottom: 60px;
	}
	&-title{
		h2{
			font-size: 24px;
			margin-bottom: 0;
			@media (max-width: 767px) {
				font-size: 18px;
			}
		}
	}
	&-set{
    	max-width: 930px;
    	margin: 0 auto;
    	@media (max-width: 767px) {
			padding-bottom: 10px;
		}
    	&__box{
    		padding-top: 37px;
    		@media (max-width: 767px) {
				padding-top: 50px;
			}
    	}
    	&__circle{
    		display: flex;
    		align-items: center;
    		justify-content: center;
    		max-width: 47px;
    		margin: 0 auto;
    		position: relative;
    		&:after{
    			content: '';
    			display: block;
    			position: absolute;
    			min-width: 50px;
    			max-width: 50px;
    			height: 50px;
    			right: -15px;
    			top: -15px;
    			background-color: #fff;
    			border-radius: 50%;
    		}
    	}
    	&__icon{
    		position: relative;
    		z-index: 1;
    		max-width: 100%;
    	}
    	&__name{
    		padding-top: 33px;
    		font-size: 13px;
			line-height: 20px;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: #000000;
			@media (max-width: 767px) {
				font-size: 12px;
	    		padding-top: 14px;
	    	}
    	}
    }
}

.affordable{
	padding-top: 68px;
	padding-bottom: 200px;
	@media (max-width: 767px) {
		text-align: center;
		padding-top: 30px;
		padding-bottom: 70px;
	}
	&-couple{
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media (max-width: 767px) {
			flex-wrap: wrap;
		}
	}
	&-title{
		padding-right: 15px;
		h2{
			font-size: 24px;
			text-transform: capitalize;
			margin-bottom: 0;
			@media (max-width: 767px) {
				font-size: 18px;
			}
		}
		@media (max-width: 767px) {
			padding-right: 0;
			width: 100%;
			padding-bottom: 30px;
		}
	}
	&-btn{
		@media (max-width: 767px) {
			width: 100%;
		}
	}
}

.stripe{
	padding: 132px 0;
	background-image: url(../images/sell-dress/stripe.jpg);
	background-repeat: no-repeat;
	background-position: center;
    background-size: cover;
    @media (max-width: 767px) {
    	padding: 66px 0;
    }
    &-content{
    	max-width: 488px;
    }
	&-title{
		h2{
			font-size: 24px;
			line-height: 34px;
			text-transform: capitalize;
			color: #FFFFFF;
			margin-bottom: 0;
			@media (max-width: 767px) {
		    	font-size: 18px;
		    	line-height: 26px;
		    }
		}
	}
	&-text{
		padding-top: 19px;
		font-size: 14px;
		line-height: 22px;
		color: #FFFFFF;
		@media (max-width: 767px) {
	    	padding-top: 12px;
	    }
	}
}

.utilise{
	padding-top: 200px;
	padding-bottom: 100px;
	text-align: center;
	@media (max-width: 767px) {
		padding-top: 70px;
		padding-bottom: 35px;
	}
	&-title{
		padding-bottom: 20px;
		h2{
			font-size: 24px;
			line-height: 34px;
			text-transform: capitalize;
			color: #1C1C1C;
			margin-bottom: 0;
			@media (max-width: 767px) {
		    	font-size: 18px;
		    	line-height: 26px;
		    }
		}
		@media (max-width: 767px) {
	    	padding-bottom: 14px;
	    }
	}
	&-text{
		max-width: 565px;
		margin: 0 auto;
		font-size: 14px;
		line-height: 22px;
		color: #1C1C1C;
	}
	&-set{
    	padding-top: 17px;
    	@media (max-width: 767px) {
			padding-top: 0;
		}
    	&__box{
    		padding-top: 30px;
    	}
    	&__icon{
    		display: block;
    		margin: 0 auto;
    		position: relative;
    		z-index: 1;
    		max-width: 100%;
    		@media (max-width: 767px) {
				max-width: 65px;
			}
    	}
    	&__name{
    		padding-top: 50px;
    		font-size: 14px;
			line-height: 22px;
			color: #000000;
			@media (max-width: 767px) {
		    	padding-top: 20px;
		    }
    	}
    }
}

.how{
	padding: 100px 0;
	@media (max-width: 767px) {
		padding: 35px 0;
	}
	&-wrap{
		position: relative;
	}
	&-title{
		text-align: center;
		h2{
			text-transform: capitalize;
			margin-bottom: 0;
			font-size: 46px;
			@media (max-width: 767px) {
				font-size: 24px;
	    	}
		}
		@media (max-width: 767px) {
			text-align: left;
    	}
	}
	&-mass{
		padding-top: 71px;
		display: flex;
		align-items: flex-start;
		position: relative;
		z-index: 1;
		@media (max-width: 991px) {
			flex-wrap: wrap;
			padding-top: 20px;
    	}
	}
	&-picture{
		width: 47%;
		@media (max-width: 991px) {
			width: 72%;
			order: 2;
			margin-left: -15px;
    	}
	}
	&-content{
		width: 53%;
	    padding-left: 5%;
    	padding-bottom: 130px;
    	@media (max-width: 991px) {
    		width: 100%;
			padding-left: 0;
			order: 1;
			padding-bottom: 40px;
    	}
	}
	&-name{
		max-width: 415px;
		font-size: 24px;
		margin-bottom: 0;
		@media (max-width: 767px) {
			font-size: 18px;
    	}
	}
	&-text{
		padding-top: 24px;
		font-size: 14px;
		line-height: 22px;
		color: #000000;
	}
	&-img{
		max-width: 100%;
		display: block;
		margin-left: auto;
		margin-top: -9%;
		@media (max-width: 991px) {
		    max-width: 75%;
		    z-index: 1;
		    position: absolute;
		    right: -23%;
		    bottom: 9%;
    	}
	}
}

.write{
	padding: 100px 0;
	@media (max-width: 767px) {
		padding-top: 35px;
		padding-bottom: 70px;
	}
	&-couple{
		display: flex;
		align-items: center;
		@media (max-width: 767px) {
			flex-wrap: wrap;
    	}
	}
	&-content{
		width: 52%;
		text-align: left;
		padding-right: 8%;
		@media (max-width: 767px) {
			width: 100%;
			padding-right: 0;
			padding-bottom: 30px;
    	}
	}
	&-title{
		h2{
			text-transform: capitalize;
			margin-bottom: 0;
			font-size: 24px;
			@media (max-width: 767px) {
				font-size: 18px;
	    	}
		}
	}
	&-text{
		padding-top: 20px;
		font-size: 14px;
		line-height: 22px;
		color: #000000;
		@media (max-width: 767px) {
			padding-top: 14px;
    	}
	}
	&-block{
		padding: 6% 4%;
		text-align: center;
		width: 48%;
		background: #FFF3EC;
		@media (max-width: 767px) {
			width: calc(100% + 30px);
			margin: 0 -15px;
			padding: 64px 15px;
    	}
    	&__topic{
    		font-size: 24px;
    		padding-bottom: 30px;
    		margin-bottom: 0;
    		@media (max-width: 767px) {
				font-size: 18px;
				padding-bottom: 24px;
				text-transform: capitalize;
	    	}
    	}
	}
}

.filters{
	padding: 103px 0;
	background: #FFF3EC;
	text-align: center;
	@media (max-width: 767px) {
		padding: 53px 0;
	}
	&-title{
		h2{
			margin-bottom: 0;
			font-size: 24px;
			@media (max-width: 767px) {
				font-size: 18px;
			}
		}
	}
	&-set{
    	padding-top: 20px;
    	@media (max-width: 767px) {
			padding-top: 0;
		}
    	&__box{
    		padding-top: 35px;
    	}
    	&__circle{
    		display: flex;
    		align-items: center;
    		justify-content: center;
    		border: 1px solid #FFC6AE;
    		min-width: 80px;
    		max-width: 80px;
    		height: 80px;
    		margin: 0 auto;
    		border-radius: 50%;
    		padding: 10px;
    	}
    	&__icon{
    		max-width: 100%;
    	}
    	&__name{
    		padding-top: 27px;
    		font-size: 14px;
			line-height: 22px;
			color: #000000;
			@media (max-width: 767px) {
				font-size: 12px;
	    		padding-top: 14px;
	    	}
    	}
    }
}

.cleaned{
	padding: 132px 0;
	background-image: url(../images/sell-dress/cleaned.jpg);
	background-repeat: no-repeat;
	background-position: center;
    background-size: cover;
    @media (max-width: 767px) {
    	padding: 55px 0;
    }
    &-content{
    	max-width: 488px;
    }
	&-title{
		max-width: 380px;
		h2{
			font-size: 24px;
			line-height: 34px;
			text-transform: capitalize;
			color: #FFFFFF;
			margin-bottom: 0;
			@media (max-width: 767px) {
		    	font-size: 18px;
		    	line-height: 26px;
		    }
		}
	}
	&-text{
		padding-top: 19px;
		font-size: 14px;
		line-height: 22px;
		color: #FFFFFF;
		@media (max-width: 767px) {
	    	padding-top: 12px;
	    }
	}
}

.pack{
	padding-top: 200px;
	padding-bottom: 100px;
	@media (max-width: 767px) {
		padding-top: 70px;
		padding-bottom: 0;
	}
	&-mass{
		padding-top: 58px;
		position: relative;
		padding-right: 50%;
		@media (max-width: 767px) {
			padding-top: 0;
			padding-right: 0;
			display: flex;
			flex-direction: column;
		}
	}
	&-title{
		max-width: 440px;
		h2{
			text-transform: capitalize;
			margin-bottom: 0;
			font-size: 24px;
			@media (max-width: 767px) {
				font-size: 18px;
			}
		}
		@media (max-width: 767px) {
			max-width: 100%;
			padding-bottom: 20px;
			order: 1;
		}
	}
	&-picture{
		position: absolute;
		max-width: 50%;
		right: -2%;
		top: 0;
		@media (max-width: 767px) {
			position: relative;
			right: 0;
			order: 4;
			max-width: calc(100% + 30px);
			margin: 0 -15px;
		}
	}
	&-content{
		padding-bottom: 68px;
		@media (max-width: 767px) {
			order: 3;
			padding-bottom: 30px;
		}
	}
	&-text{
		padding-top: 24px;
		font-size: 14px;
		line-height: 22px;
		color: #000000;
	}
	&-img{
		max-width: 100%;
		@media (max-width: 767px) {
			max-width: 73%;
			margin-left: -15px;
			order: 2;
		}
	}
}

.ending{
	margin-top: 200px;
	text-align: center;
	padding-top: 60px;
	padding-bottom: 80px;
	background: #FFF3EC;
    @media (max-width: 767px) {
    	margin-top: 70px;
		padding-top: 42px;
		padding-bottom: 55px;
	}
    &-title{
    	padding-bottom: 47px;
    	h1{
    		font-size: 46px;
    		text-align: center;
    		color: #1C1C1C;
    		margin-bottom: 0;
    		@media (max-width: 767px) {
    			font-size: 24px;
    		}
    	}
    	@media (max-width: 767px) {
			padding-bottom: 17px;
		}
    }
    &-topic{
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		color: #1C1C1C;
		padding-bottom: 12px;
		&__sub{
			font-family: 'Good Vibes Pro';
			font-weight: 400;
			font-size: 40px;
			line-height: 50px;
			text-align: center;
			text-transform: capitalize;
			color: #1C1C1C;
			padding-bottom: 47px;
			@media (max-width: 767px) {
				font-size: 36px;
				padding-bottom: 30px;
			}
		}
    }
    &-set{
    	max-width: 800px;
    	margin: 0 auto;
    	padding-bottom: 40px;
    	@media (max-width: 767px) {
			padding-bottom: 10px;
		}
    	&__box{
    		padding-bottom: 28px;
    	}
    	&__circle{
    		display: flex;
    		align-items: center;
    		justify-content: center;
    		border: 1px solid #1C1C1C;
    		min-width: 94px;
    		max-width: 94px;
    		height: 94px;
    		margin: 0 auto;
    		border-radius: 50%;
    		padding: 10px;
    		@media (max-width: 767px) {
				min-width: 58px;
	    		max-width: 58px;
	    		height: 58px;
	    		padding: 16px;
			}
    	}
    	&__icon{
    		max-width: 100%;
    	}
    	&__name{
    		padding-top: 22px;
    		font-size: 13px;
			line-height: 20px;
			text-align: center;
			letter-spacing: 0.05em;
			text-transform: uppercase;
			color: #1C1C1C;
			@media (max-width: 767px) {
				font-size: 12px;
	    		padding-top: 14px;
	    	}
    	}
    }
}