.schedule{
	padding-top: 28px;
	&__body{
		padding-top: 28px;
	}
	&__box{
		margin-bottom: 30px;
		padding: 23px 38px;
		background-color: #FFFFFF;
		box-shadow: 0px 4px 4px #FEEDE4;
		border-radius: 5px;
		@media (max-width: 991px) {
			padding: 15px;
		}
		.left-title{
			display: flex;
			align-items: center;
			h4{
				margin-bottom: 0;
				font-size: 24px;
			}
			span{
				font-size: 14px;
				color: #5B5B5B;
			}
		}
		.upload-right p{
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-top: 0;
			white-space: nowrap;
			@media (max-width: 1200px) {
				justify-content: flex-start;
				padding-top: 10px;
				white-space: normal;
			}
			a{
				margin-left: 10px;
				@media (max-width: 991px) {
					margin-left: 0;
				}
			}
			@media (max-width: 991px) {
				flex-wrap: wrap;
				justify-content: center;
			}
		}
		.size-area{
			padding: 0;
			.banner-form-btn{
				width: 70%;
			}
			.right-select{
				width: 30%;
			}
		}
		.mesurement-area{
		    padding: 15px 25px 0 25px;
		    @media (max-width: 991px) {
				padding: 22px 10px !important;
				.collapse{
					margin-top: 10px;
				}
			}
		    .right-title{
		    	margin-bottom: 18px;
		    	h4{
		    		margin-bottom: 0;
		    	}
		    	span{
		    		margin-bottom: 0;
		    	}
		    }
		}
		.designer-area{
			margin-top: 0;
			.nice-select{
				margin-top: 15px;
				float: none;
				max-width: 250px;
			}
		}
		.appearance-area{
			margin-top: 0;
		}
		.appearance-area.fabric-area{
		    padding: 32px 26px 15px 26px; 
		    @media (max-width: 991px) {
				padding: 0 !important;
				.create-col-active{
					padding: 22px 10px 10px 10px !important;
				}
			}
		}
		.price-card{
			padding: 45px 35px;
			margin-top: 10px;
			margin-bottom: 10px;
			@media (max-width: 991px) {
				padding: 22px 10px !important;
			}
			label{
				padding-top: 0;
				margin-bottom: 14px !important;
			}
			.collapse{
				margin-top: 0;
			}
		}
		.listing-card{
			padding: 26px 20px;
			height: 100%;
		}
	}
	&__style{
		.left-title{
			flex-wrap: wrap;
			h4{
				width: 100%;
			}
			p{
				margin-top: 10px;
			}
			&-sub p{
				margin-top: 0;
			}
		}
		&_list{
			padding-top: 15px;
			padding-bottom: 40px;
			display: flex;
			flex-wrap: wrap;
			margin-left: -14px;
			@media (max-width: 991px) {
				flex-wrap: nowrap;
				overflow: auto;
			}
			&_box{
				width: 25%;
				padding-left: 14px;
				padding-bottom: 20px;
				@media (max-width: 991px) {
					min-width: 200px;
				}
				.style-card, .neckline-card{
					margin: 0;
					height: 100%;
					width: 100%;
				}
			}
		}
		&_circle{
			@media (max-width: 991px) {
				min-width: 165px;
			}
			.neckline-text{
				margin-top: 10px;
			}
		}
	}
	&__cellar{
		padding-top: 22px;
		text-align: center;
	}
}