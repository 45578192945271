/*ADMIN PANEL*/
@import 'fonts';

@import 'admin/dashboard';
@import 'admin/notifications';
@import 'admin/schedule';
@import 'admin/beadroll';
@import 'admin/wishlist';
@import 'admin/profile';
@import 'admin/popup';

@import 'pages/designers';
@import 'pages/information';
@import 'pages/order';
@import 'pages/login';
@import 'pages/support';
@import 'pages/confirmation';
@import 'pages/dress';
@import 'pages/designer';
@import 'pages/sell';

@import 'admin/messages';
@import 'admin/sidebar';
@import 'admin/pagination';
@import 'admin/table';

body{
	&.no-scroll {
		overflow: hidden;
		&:before{
			content: '';
			transition: all .5s;
			z-index: 7;
			display: block;
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, .8);
			background-image: none;
		}
	}
}


.header-scroll{
	height: 0;
	position: fixed;
	left: 0;
	top: -100%;
	&.sticky {
		padding: 20px 0;
	    position: fixed;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: auto;
	    background: #fff;
	    z-index: 99999;
	    border-bottom: 1px solid #cecece;
	    -webkit-box-shadow: 0px 0px 191px 0px rgba(0, 0, 0, 0.06);
	    box-shadow: 0px 0px 191px 0px rgba(0, 0, 0, 0.06);
	    -webkit-animation: sticky 2s;
	    animation: sticky 2s;
	}
	@-webkit-keyframes sticky {
	    0% {
	        -webkit-transform: translateY(-100%);
	        transform: translateY(-100%)
	    }

	    100% {
	        -webkit-transform: translateY(0%);
	        transform: translateY(0%)
	    }
	}

	@keyframes sticky {
	    0% {
	        -webkit-transform: translateY(-100%);
	        transform: translateY(-100%)
	    }

	    100% {
	        -webkit-transform: translateY(0%);
	        transform: translateY(0%)
	    }
	}
}

.wrapper{
	position: relative;
	overflow-x: hidden;
}

.cloth{
	background-color: #FFF3EC;
	padding-bottom: 100px;
	&__title{
		font-size: 24px;
		color: #313131;
		font-family: 'Marcellus';
	}
}

.bRadius .circle {
  	stroke-linecap: round;
}
.circliful{
    height: fit-content;
    margin: 0 auto;
}
.gradients {
 	height: 0;
}
/*ADMIN PANEL*/


.left-title{
	display: flex;
	align-items: center;
	h4{
		margin-bottom: 0;
		font-size: 24px;
	}
	span{
		font-size: 14px;
		color: #5B5B5B;
	}
}
.upload-right p{
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-top: 0;
	white-space: nowrap;
	@media (max-width: 1200px) {
		justify-content: flex-start;
		padding-top: 10px;
		white-space: normal;
	}
	a{
		margin-left: 10px;
		@media (max-width: 991px) {
			margin-left: 0;
		}
	}
	@media (max-width: 991px) {
		flex-wrap: wrap;
		justify-content: center;
	}
}

.header{
	&__voting{
		margin-right: 20px;
		.banner-form-btn{
			width: auto !important;
			border: none;
		}
		.nice-select{
			padding-left: 0;
		}
	}
}

.custom-checkbox{
	display: inline-flex;
	cursor: pointer;
	align-items: center;
	.custom-control-input:focus~.custom-control-label::before{
		box-shadow: none;
		border: 1px solid #E5E5E5;
	}
	.custom-control-input:checked~.custom-control-label::before{
		border: 1px solid #E5E5E5;
		background-color: transparent;
	}
	.custom-control-label::before{
		border: 1px solid #E5E5E5;
		border-radius: 2px;
		width: 12px;
		height: 12px;
	    top: 50%;
	    transform: translate(0, -50%);
	}
	.custom-control-input:checked~.custom-control-label::after{
		background-image: url(../images/icons/check.svg);
		background-size: 70% 70%;
    	background-repeat: no-repeat;
	    background-position: center;
	}
	.custom-control-label::after{
		width: 12px;
		height: 12px;
		top: 50%;
	    transform: translate(0, -50%);
	}
	.custom-control-label{
		cursor: pointer;
		font-family: 'Montserrat';
		font-weight: 400;
		font-size: 14px;
		color: #000000;
		text-transform: capitalize;
	}
}