.login{
	padding-top: 40px;
	padding-bottom: 130px;
	&__title{
		padding-bottom: 32px;
		font-family: 'Marcellus';
		font-size: 46px;
		text-transform: capitalize;
		color: #1C1C1C;
		@media (max-width: 991px) {
			font-size: 22px;
		}
	}
	&__row{
		padding-bottom: 22px;
	}
	&__field{
		width: 100%;
		border: 1px solid #FFC6AE;
		border-radius: 5px;
		font-family: 'Montserrat';
		padding: 20px 22px;
		font-weight: 400;
		font-size: 14px;
		color: #5B5B5B;
		&::placeholder{ 
			color: #5B5B5B;
		}
		&:focus::-webkit-input-placeholder{ 
			opacity: 0; 
			transition: opacity 0.3s ease;
		}
		&:focus:-moz-placeholder{ 
			opacity: 0; 
			transition: opacity 0.3s ease; 
		} 
		&:focus::-moz-placeholder{ 
			opacity: 0; 
			transition: opacity 0.3s ease; 
		}
		&:focus:-ms-input-placeholder{ 
			opacity: 0; 
			transition: opacity 0.3s ease; 
		}
	}
	&__checkbox{
		padding-bottom: 34px;
		.ui-checkboxradio-label{
			font-family: 'Montserrat';
			font-weight: 400;
			font-size: 14px;
			line-height: 22px;
			color: #000000;
			background-color: transparent;
			border: none;
			padding: 0;
			margin: 0;
			&.ui-state-active{
				border: none;
				color: #000000;
				background-color: transparent;
			}
			.ui-checkboxradio-icon{
				border: 1px solid #E5E5E5;
				border-radius: 2px;
				margin-right: 10px;
				&.ui-state-checked{
					border: 1px solid #E5E5E5;
					background-image: url(../images/icons/check.svg);
					background-repeat: no-repeat;
					background-position: center;
					background-size: auto;
				}
			}
			
		}
	}
	&__ref{
		font-family: 'Montserrat';
		font-weight: 400;
		font-size: 14px;
		color: #FF996D;
		display: inline-block;
		margin-bottom: 32px;
		transition: all .5s;
		&:hover{
			color: #FF996D;
			opacity: 0.6;
		}
	}
	&__btn{
		padding: 12px 42px;
	}
	&__cellar{
		padding-top: 32px;
		font-family: 'Montserrat';
		font-weight: 400;
		font-size: 12px;
		color: #5B5B5B;
	}
	&__exile{
		color: #5B5B5B;
		transition: all .5s;
		&:hover{
			color: #5B5B5B;
			opacity: 0.6;
		}
	}
}